import React from "react"
import comment from '../Images/Noiva5.png'
import styled from "styled-components"
import Topicos from "./topico"

import depFernanda from '../Images/Depoimento Fernanda sem fundo.png'
import depCarol from '../Images/Depoimento Madrinha Carol sem fundo.png'
import depIngrid from '../Images/Depimento Noiva Ingrid sem fundo.png'
import depNatalia from '../Images/Depoimento Natalia.png'

const CommentItens = styled.ul`

    width:100%;
    height:500px;
    position:relative;
    float:left;
    list-style-type:none;
    display:flex;
    scroll-behavior:smooth;
    margin:0;
    overflow-x:hidden;
    padding:0;

    img{
        width:90%;
        height:auto;
    }
    li{
        width:100%;
        height:auto;
        flex:none;
    }
    li img{
        width:90%;
        margin-top:20%;
        margin-left:5%;
    }
    li h2{
        text-align:center;
        color:#FFF;
        margin-top:10%;
        margin-bottom:10%;
    }
        @media screen and (min-width: 1000px) {
            width:100%;
            height:fit-content;
            position:relative;
            float:left;
            list-style-type:none;
            display:flex;
            scroll-behavior:smooth;
            margin:0;
            overflow-x:hidden;
            padding:0;
        
            img{
                width:30%;
                height:auto;
            }
            li{
                width:50%;
                height:auto;
                flex:none;
                margin-left:25%;
                margin-right:25%;
                overflow-x:hidden;
                overflow-y:hidden;
                scroll-behavior:smooth;
            }
            li img{
                width:90%;
                margin-top:5%;
                margin-bottom:1%;
            }
            li h2{
                text-align:center;
                color:#FFF;
                margin-top:5%;
                margin-bottom:5%;
            }

        }
    }
`
const Conteiner = styled.div`

    width:100%;
    height:500px;
    display:flex;
    flex-direction:column;
    padding:0px;
    margin:0px;
    background-image:url(${comment});
    background-size:cover;
    background-position:top center;
    background-repeat:no-repeat;
    overflow-x:hidden;

    @media screen and (min-width: 1000px){
        height:auto;
    }
`

const Bolinhas = styled.ul`
    list-style-type:none;
    width:45%;
    height:30px;
    margin:0px;
    margin-left:auto;
    margin-right:auto;
    display:flex;
    justify-content:space-around;
    list-style-type:none;


 li{
    border-radius:15px;
    border:solid 2px #F2DCF1;
    width:15px;
    height:15px;
    color:#FFF;

    @media screen and (min-width: 1000px) {
        margin-bottom:30px;
    }
}
`

function Comment(){
    
    let Itens = []
    Itens.push({"nome":"Fernanda","img":depFernanda,"key":0})
    Itens.push({"nome":"Madrinha Carol","img":depCarol,"key":1})
    Itens.push({"nome":"Ingrid","img":depIngrid,"key":2})
    Itens.push({"nome":"Natália","img":depNatalia,"key":3})


    const handleChangeComment = (e) => {
        //e.preventDefault()
        
        let id = parseInt(e.target.getAttribute('id'))

        document.getElementById('listaComentarios').scrollLeft = id * parseInt(document.querySelector('#listaComentarios').offsetWidth)
    }

    const handleScroll = (e) =>{
        let left = e.target

        let distancia = Math.round(document.querySelector('#listaComentarios').scrollLeft) /document.querySelector('#listaComentarios').offsetWidth
        
        if(distancia %1 === 0){
            var ul = document.getElementById('ComBalls')
            for(var i = 0; i < ul.children.length ; i++){
                 document.querySelectorAll('#ComBalls li')[i].style.backgroundColor ='transparent'
                 if(parseInt(ul.children[i].getAttribute('id')) === parseInt(distancia)){
                    ul.children[i].style.backgroundColor = '#F2DCF1'
                }else if(distancia > i-1 && distancia < i+1){
                    ul.children[i].style.backgroundColor = '#F2DCF1'
                }
            }

        }
    }

    return(
        <>
            <Topicos partUm='COMEN' partDois='TÁRIOS' id='topicoComentario' width='50%'/>
            <Conteiner>
                <CommentItens id='listaComentarios' onScroll={handleScroll}>
                    {
                        Itens.map((itemAtual, i) =>{

                            return(
   
                                <li key={itemAtual.key+itemAtual.key} id={itemAtual.key} className='CommentItem'>
                                    <h2>{itemAtual.nome}</h2>
                                    <img src={itemAtual.img} loading="lazy" alt={itemAtual.nome}/>
                                </li>
                            )
                        })
                    }
                </CommentItens>
                <Bolinhas id='ComBalls'>
                    {
                        Itens.map((itemAtual, i) =>{
                            return(
                                <li key={itemAtual.key} id={i} onClick={handleChangeComment}>
                                </li>
                            )
                        })
                    }
                </Bolinhas>
            </Conteiner> 
        </>
    )
}


export default Comment
