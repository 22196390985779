import React, { useState } from "react"
import styled from "styled-components"
import Topicos from "./topico"
import dois from '../Images/Noiva 1.jpg'
import tres from '../Images/Noiva 11.jpg'
import quatro from '../Images/Produção Social 1.jpg'
import { Animated } from "react-animated-css"



import Img1 from '../Images/Portfolio/fundoDepoimentos.jpg'
import Img2 from '../Images/Portfolio/IMG_5642.jpg'
import Img3 from '../Images/Portfolio/IMG_5643.jpg'
import Img4 from '../Images/Portfolio/IMG_5644.jpg'
import Img5 from '../Images/Portfolio/MADRINHA_.jpg'
import Img6 from '../Images/Portfolio/Making of 1.jpg'
import Img7 from '../Images/Portfolio/Making of 2.jpg'
import Img8 from '../Images/Portfolio/Making of 3.jpg'
import Img9 from '../Images/Portfolio/Making of 4.jpg'
import Img10 from '../Images/Portfolio/Making of 5.jpg'
import Img11 from '../Images/Portfolio/Making of 6.jpg'
import Img12 from '../Images/Portfolio/Making of 7.jpg'
import Img13 from '../Images/Portfolio/Making of 8.jpg'
import Img14 from '../Images/Portfolio/Making of 10.jpg'
import Img15 from '../Images/Portfolio/Noiva 1.jpg'
import Img16 from '../Images/Portfolio/Noiva 4.Ingrid.jpg'
import Img17 from '../Images/Portfolio/Noiva 6.jpg'
import Img18 from '../Images/Portfolio/Noiva 7.jpg'
import Img19 from '../Images/Portfolio/Noiva 10.Ingrid.jpg'
import Img20 from '../Images/Portfolio/Noiva 11.jpg'
import Img21 from '../Images/Portfolio/NOIVA NATÁLIA.jpg'
import Img22 from '../Images/Portfolio/NOIVA(1).jpg'
import Img23 from '../Images/Portfolio/Noiva.jpg'
import Img24 from '../Images/Portfolio/NOIVA_.jpg'
import Img25 from '../Images/Portfolio/Produção Social 1.jpg'
import Img26 from '../Images/Portfolio/Produção Social 2.jpg'


const Conteiner = styled.section`
    width:100%;
    height:fit-content;
    display:flex;
    flex-direction:column;
    overflow-x:hidden;
    overflow-y:hidden;
`


const Portifolio = styled.section`
    width:100%;
    height:fit-content;
    position:relative;
    float:left;
    margin-bottom:10px;
    overflow-x:hidden;
    overflow-y:hidden;
    display:flex;
    scroll-behavior:smooth;

    *{
        margin:0px;
        padding:0px;
    }

    ul{
        list-style-type:none;
        display:grid;
        grid-template-column:48% 48%;
        grid-template-rows:33% 33% 33%;
        height:480px;
        grid-gap:1%;
        width:100%;
        flex:none;
        overflow-x:hidden;
        overflow-y:hidden;
    }
    li{
        width:97%;
        height:97%;
        transition: transform .2s;
        overflow:hidden;
        display:flex;
        justify-content:center;
    }
    li:hover{
        background-color: whitesmoke;
        transform: scale(1.03);
        cursor: pointer;
    }
    li img{
        width:auto;
        height:100%;
        top:50%
        position:relative;
        clip-path: fill-box;
    }
    .um{
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:1;
        grid-row-end:2;
        background-size:cover;
        background-position:top center;
        background-repeat:no-repeat;
    }
    ul .dois{
        grid-column-start:1;
        grid-column-end:2; 
        grid-row-start:2;
        grid-row-end:4; 
        background-size:cover;
        background-position:top center;
        background-repeat:no-repeat;
    }
    ul .tres{
        grid-column-start:2;
        grid-column-end:3; 
        grid-row-start:1;
        grid-row-end:3; 
        background-size:cover;
        background-position:top center;
        background-repeat:no-repeat;
    }
    ul .quatro{
        grid-column-start:2;
        grid-column-end:3; 
        grid-row-start:3;
        grid-row-end:4; 
        background-size:cover;
        background-position:top center;
        background-repeat:no-repeat;
    }

    .NavegaPortifolio{
        width:50%;
        margin-left:25%;
        display:flex;
        height:40px;
        margin-top:20px;
        margin-bottom:20px;
        flex:none;
        position:absolute;
        margin-top:520px;
    }
    .NavegaPortifolio span{
        width:30%;
        height:auto;
        font-size:18px;
        text-align:center;
    }


    @media screen and (min-width: 1300px){
        width:80%;
        margin-left:10%;
        overflow:hidden;

    ul{
        list-style-type:none;
        display:grid;
        grid-template-column:46% 46%;
        grid-template-rows:33% 33% 33%;
        height:780px;
        grid-gap:1%;
        width:100%;
        flex:none;
    }
    li{
        width:92%;
        height:92%;
        transition: transform .2s;
        overflow:hidden;
        display:flex;
        justify-content:center;
    }
    li:hover{
        background-color: whitesmoke;
        transform: scale(1.03);
        cursor: pointer;
    }
    li img{
        width:auto;
        height:100%;
        top:50%
        position:relative;
        clip-path: fill-box;
    }
    }
`
 const NavegaPortifolio = styled.div`
    width:100%;
    height:60px;
    font-size:20px;
    text-align:center;
    color:#333;
    
    *{
        margin:0;
        padding:0;
    }
    ul{
        list-style-type:none;
        display:flex;
        width:100%;
        align-itens:center;
        justify-content:center;
    }
    li{
        width:30px;
        height:auto;
        padding:5px;
        box-shadow:1px 1px 2px #B98DBE;
        flex:none;
        background-color:#FFF;
    }
    li:hover{
        cursor:pointer;
    }
 ` 

 const Modal = styled.div`
    width:100%;
    height:100%;
    overflow:none;
    background-color:RGB(220, 220, 220, 0.6);
    padding:0px;
    margin:0px;
    display:flex;
    z-index:9999;
    position:fixed;
    float:left;
    align-items: center;
    align-content: center;

    img{
        width:90%;
        height:auto;
        margin-left:auto;
        margin-right:auto;
    }
    @media screen and (min-width: 1000px){

        background-color:RGB(220, 220, 220, 0.3);

        img{
            height:60%;
            width:auto;
        }
    }
    
 `
 

function Portifolios(){

    const [img, setIMG] = useState(false);
    const [imgModal, setImgModal] = useState('');

    let Itens =[
        {imagem:Img1,key:0},
        {imagem:Img2,key:1},
        {imagem:Img3,key:2},
        {imagem:Img4,key:3},
        {imagem:Img5,key:4},
        {imagem:Img6,key:5},
        {imagem:Img7,key:6},
        {imagem:Img8,key:7},
        {imagem:Img9,key:8},
        {imagem:Img10,key:9},
        {imagem:Img11,key:10},
        {imagem:Img12,key:11},
        {imagem:Img13,key:12},
        {imagem:Img14,key:13},
        {imagem:Img15,key:14},
        {imagem:Img16,key:15},
        {imagem:Img17,key:16},
        {imagem:Img18,key:17},
        {imagem:Img19,key:18},
        {imagem:Img20,key:19},
        {imagem:Img21,key:20},
        {imagem:Img22,key:21},
        {imagem:Img23,key:22},
        {imagem:Img24,key:23},
        //{imagem:Img25,key:24},
        //{imagem:Img26,key:25}
    ]

    let contador =0

    return(
        <>
            <Topicos partUm='PORT' partDois='FÓLIO' id='topicoPortifolio' width='50%'/>

            <Conteiner>

                <Portifolio id='DivPortifolio'>
                    {
                        Itens.map((ItemAtual, i) => {
                            if(i%4===0){


                            return(

                                <ul onClick={(e) =>{
                                    e.preventDefault()
                                    if(e.target.nodeName === 'IMG'){
                                        setIMG(true)
                                        setImgModal(e.target.getAttribute('src'))
                                    }
                                }}>
                                    <li className='um' key={'IMG'+ItemAtual.key}> 
                                        <img src={ItemAtual.imagem} alt='Imagens da Galeria' id='testeIMG' />
                                    </li>
                                    <li className='dois' key={'IMG'+ItemAtual.key+1}>
                                        <img src={Itens[i+1].imagem} alt='Imagens da Galeria' />
                                    </li>
                                    <li className='tres' key={'IMG'+ItemAtual.key+2}>
                                        <img src={Itens[i+2].imagem} alt='Imagens da Galeria' />
                                    </li>
                                    <li className='quatro' key={'IMG'+ItemAtual.key+3}>
                                        <img src={Itens[i+3].imagem} alt='Imagens da Galeria' />
                                    </li>
                                </ul>
                                )
                                }
                            })
                        }                    
                </Portifolio>
                
                <NavegaPortifolio>
                    <ul>
                    {
                        Itens.map((ItemAtual,i) =>{
                            
                            if(i%4===0){
                                ++contador
                                return(
                                    <li key={contador} onClick={(e) =>{
                                            
                                            let largura = document.getElementById('DivPortifolio').offsetWidth
                                            let itemPosicao = i/4
                                            document.getElementById('DivPortifolio').scrollLeft = itemPosicao * largura
                                        }
                                    }>
                                        {contador}</li> 
                                )
                            }
                        })
                    }
                    </ul> 
                </NavegaPortifolio>

            </Conteiner>

            <Animated 
                animationIn="fadeIn" 
                animationOut="fadeOut" 
                animationInDuration={500} 
                animationOutDuration={500}
                isVisible={img} 
                style={{backgroundColor: 'RGB(0, 0, 0, 0.9)', position:'fixed', zIndex:9999, width:'100%', height:'100%', top:'0px'}}
            >
                <Modal onClick={(e) =>{
                    e.preventDefault()

                    if(e.target.nodeName !== 'IMG'){
                        setIMG(false)
                    }

                }}>
                    <img src={imgModal} alt='imagem nova' />
                </Modal>
            </Animated>
        </>
    )
}

export default Portifolios