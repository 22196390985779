import React,{ useState } from "react"
import Topicos from "./topico"
import endereco from '../Images/Endereço.png'
import styled from "styled-components"
import { Animated } from "react-animated-css"

const Endereco = styled.section`
    width:100%;
    min-height:500;
    position:relative;
    float:left;
    margin-top:10px;
    margin-bottom:20px;

    *{
        font-family:Camaly;
        font-weight:600;
        font-size:18px;
    }

    img{
        width:90%;
        margin:0px;
        margin-left:5%;
        position:relative;
        float:left;
    }
    div{
        position:relative;
        float:left;
        width:100%;
        height:80px;
    }
    div p{
        font-size:16px;
        font-weight:300px;
        color:#333;
        text-align:center;
        margin-bottom:10px;
        line-height:16px;
    }
    h2{
        width:fit-content;
        margin:0px;
        margin-left:auto;
        margin-right:auto;
    }
    form{
        width:90%;
        margin-left:5%;
        display:flex;
        flex-direction:column;
    }
    form input{
        width:90%;
        margin-left:5%;
        height:30px;
        margin-top:10px;
        border:none;
        border-bottom:solid 0.3px #333;
        font-size:18px;
    }
    form textarea{
        width:90%;
        margin-left:5%;
        margin-top:10px;
        border:none;
        border-bottom:solid 0.3px #333;
        max-height:60px;
        min-height:60px;
        resize:none;
        margin-bottom:20px;
        font-size:18px;
    }
    form button{
        background-color:#B98DBE;
        border:none;
        color:#FFF;
        padding:10px;
        padding-left:20px;
        padding-right:20px;
        width:fit-content;
        height:auto;
        position:relative;
        float:RIGHT;
        margin:0;
        margin-left:72%;
        margin-top:10px;
        margin-bottom:20px;
        font-size:22px;
        font-weight:800;
    }

    form button:hover{
        cursor:pointer;
    }
    @media screen and (min-width: 1000px){
    
        img{
            width:60%;
            margin-left:20%;
        }
        div{
            position:relative;
            float:left;
            width:100%;
            height:80px;
            font-size:24px;
        }
        div p{
            font-size:24px;
            font-weight:300px;
            color:#333;
            text-align:center;
            margin-bottom:10px;
            line-height:12px;
        }
        h2{
            width:100%;
            margin:0px;
            margin-left:auto;
            margin-right:auto;
            font-size:32px;
            margin-top:30px;
            padding-bottom:10px;
            position:relative;
            float:left;
            text-align:center;
        }
        form{
            width:90%;
            margin-left:5%;
            display:flex;
            flex-direction:column;
        }
        form input{
            width:60%;
            margin-left:20%;
            height:30px;
            margin-bottom:30px;
            border:none;
            border-bottom:solid 0.3px #333;
            font-size:28px;
        }
        form textarea{
            width:60%;
            margin-left:20%;
            margin-top:20px;
            border:none;
            border-bottom:solid 0.3px #333;
            max-height:60px;
            min-height:60px;
            resize:none;
            margin-bottom:20px;
            font-size:28px;
        }
        form button{
            background-color:#B98DBE;
            border:none;
            color:#FFF;
            padding:10px;
            padding-left:20px;
            padding-right:20px;
            width:fit-content;
            height:auto;
            position:relative;
            float:RIGHT;
            margin:0;
            margin-left:72%;
            margin-top:10px;
            margin-bottom:20px;
            font-size:32px;
            font-weight:800;
        }
    }
`


function Contato(){

        const [mensagem, setMensagem] = useState({
            nome: '',
            email: '',
            conteudo: ''
        })

        const [mensVisivel,setMensVisivel] = useState(false)
        const [corMensagem,setCorMensagem] = useState('#66CDAA')

        const handleNome = e => {
            setMensagem({...mensagem, [e.target.name]: e.target.value})
        }

        const handleEnvioEmail = async e =>{ 
            e.preventDefault()

            await fetch("https://natalialuccena.com.br/API/send.php", {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({mensagem})
            }).then((response) => response.json()).then((responseJson) => {
                if(responseJson.erro){

                    setMensVisivel(true)
                    
                    setCorMensagem('#FF7F50')

                    document.getElementById('MensagemRetornoContato').innerText = responseJson.mensagem

                    let inputs = document.querySelectorAll('input, textarea')
                    for(let i=0; i< inputs.length;i++){
                        inputs[i].value = ''
                    }

                    setTimeout(function(){
                        setMensVisivel(false)
                    },4000)
                }else{

                    setMensVisivel(true)
                    setCorMensagem('#66CDAA')

                    document.getElementById('MensagemRetornoContato').innerText = responseJson.mensagem

                    let inputs = document.querySelectorAll('input, textarea')
                    for(let i=0; i< inputs.length;i++){
                        inputs[i].value = ''
                    }

                    setTimeout(function(){
                        setMensVisivel(false)
                    },4000)
                }
            })

        }
    return(

        <>
            <Topicos partUm='CON' partDois='TATO' id='topicoContato'  width='50%'/>
            <Endereco>
                <img src={endereco} alt='foto do prédio' />
                <div>
                    <p><b>Telefone:</b>(21) 98057-2381 <b>E-mail</b> contato@natalialuccena.com.br</p>
                    <p><b>Endereço:</b>Rua General Andrade Neves, 9 - Sala 1418/1419 24210-000 Niterói, RJ</p>
                </div>
                <h2>Entre em Contato!</h2>
                <form onSubmit={handleEnvioEmail}>

                    <input type='text' name='nome' placeholder='Nome' onChange={handleNome} required/>
                    <input type='email' name='email' placeholder='E-mail'  onChange={handleNome} required/>
                    <textarea name='conteudo' placeholder='Mensagem'  onChange={handleNome} required></textarea>
                    <button>Enviar</button>

                </form>
            </Endereco>
            <Animated 
                animationIn="fadeInRight" 
                animationOut="fadeOutRight" 
                animationInDuration={500} 
                animationOutDuration={500}
                isVisible={mensVisivel}
                style={
                        {backgroundColor: corMensagem, 
                        position:'fixed', 
                        zIndex:99999, 
                        minWidth:'200px', 
                        minHeight:'30px', 
                        float:'right',
                        padding:'5px',
                        margin:'0px', 
                        bottom:'70px',
                        right:'10px', 
                        textAlign:'center',
                        color:'#FFF',
                        borderRadius:'5px',
                        fontFamily:'Camaly',
                        fontSize:'22px',
                        fontWeight:'600'
                    }
                    }
            >
                <p id='MensagemRetornoContato'></p>
            </Animated>
        </>

    )

}

export default Contato