import React, { useState } from 'react'
import styled from 'styled-components'
import logo from '../Images/logoNL.png'
import menu from '../Images/mobile_menu.png'
import imgban from '../Images/banner.jpg'
import {Animated} from "react-animated-css"
import Xmenu from '../Images/x.png'
import youtube from '../Images/youtube.png'
import instagram from '../Images/instagram.png'
import facebook from '../Images/facebook.png'
import twitter from '../Images/twitter.png'


const Banner = styled.section`
    width:100%;
    height:${window.screen.width * 0.6413}px;
    background-image:url(${imgban});
    background-size:cover;
    background-repeat:no-repeat;
    z-index:100;
    position:relative;

    .menuDesktop, header .menuDesktop{
        display:none;
    }

    *{
        padding:0px;
        margin:0px;
        z-index:100;
    }
    header{
        width:100%;
        height:50px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        position:fixed;
        z-index:999;
    }
    header aside{
        width:100%;
        height:50px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }
    .logoHeader{
        height:50px;
        width:auto;
        margin:0;
        padding:0;
        margin-left:20px;
    }
    #logo, #logo img{
        width:auto;
        height:90px;
        padding:5px;
        position:absolute;
        float:right;
        right:0px;
        margin-top:60px;
    }
    aside img{
        width:auto;
        height:30px;
        padding:10px; 
        z-index:100;
        position:relative;
    }



    @media screen and (min-width: 1000px){
        height:${window.innerHeight}px;
        /*width: ${(window.innerHeight)*1.559}px;
        max-width: ${(window.innerHeight)*1.559}px;*/

        margin-left:auto;
        margin-right:auto;

        .menuDesktop{
            display:flex;
            width:100%;
            height:50px;
            list-style-type:none;
            color:#FFF;
            font-family:Camaly;
            font-weight:800;
            text-align:center;
            justify-content:space-around;
            background-color:#A093AA;
            align-items:center;
            position:absolute;
            bottom:0px;
            font-size:22px;
            z-index:999;
        }
        .menuHamburguer{
            display:none;
        }
        .logoHeader{
            height:170px;
            width:auto;
            margin:0;
            padding:0;
            margin-left:30px;
            margin-top:0px;
        }
        header{
            height:100px;
            display:flex;
            flex-direction:row;
            align-items:center;
            z-index:999;
            margin:0;
            width:100%;
            left:0;
        }
        header .menuDesktop{
            height:100px;
            z-index:9999;
            margin-top:0px;
            background-color:#FFF;
            color:#A093AA;
        }
        header .menuDesktop li, .menuDesktop li{
            height:fit-content;
            margin-top:50px;
            padding:10px;
            font-size:22px;
            margin-top:0px;
            z-index:9999;
        }
        .menuDesktop li{
            margin-top:0px;
        }
        header .menuDesktop li:hover, .menuDesktop li:hover{
            background-color:#886E6E;
            cursor:pointer;
            box-shadow:inset 1px 1px 2px #333;
        }

        header *{
            z-index:9999;
        }
        header aside{
            display:;
        }
    }

`;

const MenuMobile = styled.section`
    width:100%;
    height:100%;
    overflow:none;
    background-color:#FFF;
    padding:0px;
    margin:0px;
    display:block;
    z-index:99999;
    position:fixed;
    float:left;

    header{
        display:flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        z-index:99999;
        background-color:#FFF;
    }

    #logoMenu img{
        width:auto;
        height:50px;
        margin:0px;
        margin-left:20px;
        z-index:99999;
        background-color:#FFF;
        position:fixed;
    }
    aside img{
        width:auto;
        height:30px;
        padding:10px; 
    }
    ul{
        list-style-type:none;
        width:70%;
        margin-left:auto;
        margin-right:auto;
        text-align:center;
    }
    li{
        width:100%;
        height:50px;
        line-height:40px;
        font-family:Oswald;
        color:#444;
    }
    li:hover{
        cursor:pointer;
    }
    .listaMenu{
        margin-top:20%;
        width:fit-content;
        padding:0px;
        font-size:20px;
    }
    .listaRedes{
        display: grid;
        grid-gap:10px;
        width:fit-content;
        height:100px;
        margin-left:auto;
        margin-right:auto;
        grid-template-columns:50% 50%;
        grid-template-rows:50% 50%;
        padding:0px;
    }
    .listaRedes li{
        width:100%;
        height:fit-content;
    }
    .listaRedes li img{
        width:45px;
        height:auto;
    }
    @media screen and (min-width: 1300px){
        #logoMenu img{
            width:auto;
            height:50px;
            margin:0px;
            margin-left:20px;
            z-index:99999;
            background-color:#FFF;
            position:fixed;
        }
    }
`



function BannerPage() {

        const [menuMobile, setMenuMobile] = useState(false)
        

        const handleLinkMenu = (e) =>{
            setMenuMobile(false)
            document.getElementById('tst').children[0].children[0].children[0].style.zIndex=999

            if(e.target.innerText === 'HOME'){
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
            }
            
            if(e.target.innerText === 'SOBRE'){
                document.body.scrollTop = document.getElementById('topicoSobre').offsetTop - 70 // For Safari
                document.documentElement.scrollTop = document.getElementById('topicoSobre').offsetTop - 70
            }
            
            if(e.target.innerText === 'NOSSOS SERVIÇOS'){
                document.body.scrollTop = document.getElementById('topicoServicos').offsetTop - 70 // For Safari
                document.documentElement.scrollTop = document.getElementById('topicoServicos').offsetTop - 70
            }
            
            if(e.target.innerText === 'COMENTÁRIOS'){
                document.body.scrollTop = document.getElementById('topicoComentario').offsetTop - 70 // For Safari
                document.documentElement.scrollTop = document.getElementById('topicoComentario').offsetTop - 70
            }
            
            if(e.target.innerText === 'PORTFÓLIO'){
                document.body.scrollTop = document.getElementById('topicoPortifolio').offsetTop - 70 // For Safari
                document.documentElement.scrollTop = document.getElementById('topicoPortifolio').offsetTop - 70
            }
            
            if(e.target.innerText === 'CONTATO'){
                document.body.scrollTop = document.getElementById('topicoContato').offsetTop - 70 // For Safari
                document.documentElement.scrollTop = document.getElementById('topicoContato').offsetTop - 70
            }


        }

        window.onscroll = () => {
            
            if(document.body.offsetWidth > 1000){
                if(window.scrollY > 150){
                    document.querySelectorAll('.menuDesktop')[1].style.display = 'none'
                    document.querySelectorAll('.logoHeader')[0].style.display = 'none'
                    document.querySelector('header aside').style.display = 'none'
                    document.querySelector('header .menuDesktop').style.display = 'flex'
                    document.querySelector('header .menuDesktop').style.zIndex = '9999'
                }else{
                    document.querySelector('header aside').style.display = 'block'
                    document.querySelectorAll('.logoHeader')[0].style.display = 'flex'
                    document.querySelectorAll('.menuDesktop')[1].style.display = 'flex'
                    document.querySelector('header .menuDesktop').style.display = 'none'
                }
            }else{

                if(window.scrollY > 150){
                    document.getElementById('tst').children[0].style.backgroundColor = '#FFF'
                }else{
                    document.getElementById('tst').children[0].style.backgroundColor = 'transparent'
                }
            }
           // console.log(window.scrollY)
        }

        

    return(

        <>
            <Animated 
                animationIn="slideInRight" 
                animationOut="slideOutRight" 
                animationInDuration={500} 
                animationOutDuration={500}
                isVisible={menuMobile} 
                style={{backgroundColor: '#FFF', position:'fixed', zIndex:9999, width:'100%', height:'100%'}}
            >   
                    <MenuMobile id='menuFechar'>
                        <header>

                            <aside onClick={ ()=>{
                                    setMenuMobile(false)
                                    document.getElementById('tst').children[0].children[0].children[0].style.zIndex=999

                                }}>
                                <img src={Xmenu} alt='imagem do menu'/>
                            </aside>

                            <div id='logoMenu'>
                                <img src={logo} alt='imagem de logo'/>
                            </div>

                        </header>

                        <ul className='listaMenu' onClick={handleLinkMenu}>
                            <li>HOME</li>
                            <li>SOBRE</li>
                            <li>NOSSOS SERVIÇOS</li>
                            <li>COMENTÁRIOS</li>
                            <li>PORTFÓLIO</li>
                            <li>CONTATO</li>
                        </ul>
                        <ul className='listaRedes'>
                            <li><img src={twitter} alt='Icone Twitter' /></li>
                            <a href='https://www.facebook.com/atelienatalialucena'>
                                <li>
                                    <img src={facebook} alt='Icone facebook' />
                                </li>
                            </a>
                            <a href='https://www.instagram.com/natalialuccena/'>
                                <li>
                                    <img src={instagram} alt='Icone instagram' />
                                </li>
                            </a>
                            <li><img src={youtube} alt='Icone youtube' /></li>
                        </ul>
                    </MenuMobile>
            </Animated>
            <Banner id='tst'>

                <header>

                    <aside  onClick={ ()=>{
                        if(window.screen.width < 1300){
                            setMenuMobile(true)
                        }
                        document.getElementById('tst').children[0].children[0].children[0].style.zIndex=0
                    }}>
                        <img src={logo} alt='imagem de logo' className='logoHeader'/>
                        <img src={menu} alt='imagem do menu' className='menuHamburguer'/>
                    </aside>

                        <ul className='menuDesktop' onClick={handleLinkMenu}>
                            <img src={logo} alt='imagem de logo' className='logoHeader'/>
                            <li key='menuDesktop0'>HOME</li>
                            <li key='menuDesktop1'>SOBRE</li>
                            <li key='menuDesktop2'>NOSSOS SERVIÇOS</li>
                            <li key='menuDesktop3'>COMENTÁRIOS</li>
                            <li key='menuDesktop4'>PORTFÓLIO</li>
                            <li key='menuDesktop5'>CONTATO</li>
                        </ul>

                </header>

                <ul className='menuDesktop' onClick={handleLinkMenu}>
                    <li key='menuDesktop0'>HOME</li>
                    <li key='menuDesktop1'>SOBRE</li>
                    <li key='menuDesktop2'>NOSSOS SERVIÇOS</li>
                    <li key='menuDesktop3'>COMENTÁRIOS</li>
                    <li key='menuDesktop4'>PORTFÓLIO</li>
                    <li key='menuDesktop5'>CONTATO</li>
                </ul>
            </Banner>
            
        </>

    )
}
export default BannerPage