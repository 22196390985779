import React from 'react'
import styled from 'styled-components'
import aboutimg from '../Images/about.jpg'
import Topicos from './topico.js'

const About = styled.section`
    width:100%;
    height:fit-content; /*${window.screen.width * 0.70}px*/
    display:grid;
    grid-template-columns:50% 50%;
    grid-template-rows:fit-content fit-content fit-content fit-content;
    flex-direction:column;
    margin-top:20px;
    justify-content: flex-end;
   

    img{
        width:96%;
        height:auto;
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:1;
        grid-row-end:4;
        margin-top:20px;
    }
    .topico{
        width:100%;
        grid-column-start:2;
        grid-column-end:3;
        grid-row-start:1;
        grid-row-end:2;
        border:solid;
    }
    .NL{ 
        width:100%;
        grid-column-start:2;
        grid-column-end:3;
        grid-row-start:2;
        grid-row-end:3;
        font-size:18px;
        color:#B98DBE;
        font-weight:400;
        padding:0px;
        margin:0px;
        text-align:center;
    }
    .textoNL{
        grid-column-start:2;
        grid-column-end:3;
        grid-row-start:3;
        grid-row-end:4;
        font-size:18px;
    }
    .textoNL2{
        grid-column-start:1;
        grid-column-end:3;
        grid-row-start:4;
        grid-row-end:5;
    }
    div p{
        width:90%;
        font-size:17px;
        color:#333;
        font-weight:600;
        margin:0px;
        text-align:left;
        margin-left:5%;
        padding-top:10px;
        font-family:'Camaly';
    }


    @media screen and (min-width: 1000px){
        grid-template-rows:fit-content fit-content fit-content;

        img{
            width:60%;
            margin-left:20%;
            grid-row-end:5;
        }
        .NL{ 
            width:100%;
            font-size:36px;
            height:100px;
            grid-column-start:2;
            grid-column-end:3;
            grid-row-start:2;
            grid-row-end:3;
        }
        .textoNL{
            grid-column-start:2;
            grid-column-end:3;
            grid-row-start:3;
            grid-row-end:4;
        }
        .textoNL2{
            grid-column-start:2;
            grid-column-end:3;
            grid-row-start:4;
            grid-row-end:5;
        }
        div p{
            font-size:${document.body.offsetWidth * 0.02}px;
            font-weight:700;
        }
    }

`;

function AboutShe(){

    return(

        <>
            <About>
                <Topicos partUm='SOB' partDois='RE' className='topico' id='topicoSobre' width='100%'/>
                <img src={aboutimg} alt='imagem da natalia'/>

                <h1 className='NL'>NATALIA LUCCENA</h1>
                <div className='textoNL'>
                    <p>

                    Esse dia tão esperado pela noiva, deve ser inesquecível, o
                    mais tranquilo possível e de forma que só um profissional
                    com experiência e reconhecido no mercado poderá
                    oferecer, pois pensará em todos os detalhes. 

                    </p>
                </div>
                <div className='textoNL2'>
                    <p>
                    Eu organizo
                    cada etapa, desde a cor do esmalte que vou usar até sua
                    entrada na igreja, para que tudo seja perfeito.
                    Você, futura Noiva Natália Luccena, contará com um
                    serviço previsto em contrato personalizado e exclusivo,
                    pois, entendo que o dia da Noiva
                    envolve muitas expectativas e grandes responsabilidades,
                    então me dedico inteiramente a você.

                    </p>
                </div>
            </About>

        </>

    )
}

export default AboutShe