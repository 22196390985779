import React, { useState } from 'react'
import styled from 'styled-components'
import prodsocial from '../Images/Portfolio/IMG_5644.jpg'
import noiva from '../Images/Noiva 11.jpg'
import makingof from '../Images/Portfolio/makingOf.jpeg'
import Topicos from './topico.js'
import { Animated } from 'react-animated-css'
import setaEsquerda from '../Images/seta_esquerda.png'
import setaDireita from '../Images/seta_direita.png'
import parse from 'html-react-parser'

const Listaservicos = styled.ul`
    flex:none;
    width:100%;
    max-height:fit-content;
    position:relative;
    list-style-type:none;
    padding:0px;
    padding-top:10px;
    margin:0px;
    display:flex;
    overflow-y:none;
    overflow-x:none;

    #ServicosDiv{
        width:80%;
        flex:none;
        max-height:fit-content;
        padding:0px;
        margin:0px;
        left:10%;
        display:flex;
        justify-content:space-between;
        overflow-x:hidden;
        overflow-y:hidden;
        scroll-behavior: smooth; 
        position:relative;
    }

    .setas{
        position:absolute;
        z-index:99;
        width:40px;
        height:auto;
        padding:0px;
        margin:0px;
        flex:none;
    }
    .setas:first-child{
        left:5px;
    }
    
    .setas:last-child{
        right:5px;
        float:right;
    }

    @media screen and (min-width: 1000px){

        #ServicosDiv{
            flex:none;
            display:flex;
            justify-content: flex-start;
            overflow-y:hidden;
            align-items: flex-start;
            align-content: flex-start;
            width:80%;
            margin-right:10%;

        }
        .setas{
            width:${document.body.offsetWidth * 0.06}px;
            flex:none;
        }
    }
`

const ItemLista = styled.li`
    width:100%;
    height:auto;
    padding:0px;
    margin-left:0px;
    position:relative;
    flex:none;
    color:#B98DBE;

    img{
        width:100%;
        max-height:auto;
        position:relative;
        flex:none;
    }

    h2{
        z-index:30;
        background-color:rgb(203,152,219, 0.8);
        color:#FFF; 
        margin:0px;
        padding-top:10px;
        padding-bottom:10px;
        font-family:Calibri;   
        margin-top:150px;  
        width:100%;
        font-size:15px;
        position:absolute;
        text-align:center;
        flex:none;

    }
    div{
        width:100%;
        max-height:fit-content;
        overflow-y:none;
        position:relative;
        flex:none;

    }
    div p{
        width:80%;
        margin-left:auto;
        margin-right:auto;
        font-family:'Camaly';
        font-size:17px;
        font-weight:600;
        flex:none;
    }

    @media screen and (min-width: 1000px){
        flex:none;
        max-width:40%;
        padding:0px;
        margin-left:${document.body.offsetWidth * 0.05}px;
        margin-right:${document.body.offsetWidth * 0.05}px;
        text-align:center;
    
        h2{  
            font-size:22px;
            flex:none;
            width:100%;
            margin:0px;
            margin-top:45%;
        }
        div{
            width:auto;
            max-height:500px;
            overflow-y:hidden;
            flex:none;
        }
        div p{
            width:80%;
            margin-left:auto;
            margin-right:auto;
            font-family:'Camaly';
            font-size:${document.body.offsetWidth * 0.02}px;
            font-weight:600;
            flex:none;
        }
    }
`

function ListaServicos(){

    const textoMakingOf = '<p>Preparação da Noiva para as fotos fazendo a simulação da produção.<br />Acompanhamento na colocação do vestido e sapatos.<br />Apoio direto a Noiva durante a sessão de fotos.</p>'
    const textoNoiva = '<p>Contrato de Exclusividade de Data.<br />Atendimento personalizado.<br />Reuniões de Briefing para entender qual é o seu sonho para este dia.<br />Acompanhamento da assinatura do contrato até ida ao altar.<br />Acompanhamento durante a festa.<br />Produtos de altíssima qualidade com máxima durabilidade.</p>'
    const textoProdSocial = '<p>Contrato de prestação de serviço.<br />Atendimento personalizado.<br />Identificação da produção ideal para o dia e temática do evento social.<br />Produtos de altíssima qualidade com máxima durabilidade.</p>'

    const [visivel, setVisivel] = useState()

    let Itens = []
    Itens.push({"nome":"PRODUÇÃO SOCIAL","img":prodsocial,"key":0,"texto":textoProdSocial})
    Itens.push({"nome":"NOIVA","img":noiva,"key":1,"texto":textoNoiva})
    Itens.push({"nome":"MAKING OF","img":makingof,"key":2,"texto":textoMakingOf})

    

    let handleSetaEsquerda = (e) => {
       let ServicosDiv = document.getElementById('ServicosDiv')
        if(document.body.offsetWidth >= 1000 ){
        ServicosDiv.scrollLeft -= document.querySelectorAll('#ServicosDiv img')[0].offsetWidth * 1.32
        }else{
            ServicosDiv.scrollLeft -= document.querySelectorAll('#ServicosDiv img')[0].offsetWidth
        }
    }
    

    let handleSetaDireita = (e) => {
       let ServicosDiv = document.getElementById('ServicosDiv')
       if(document.body.offsetWidth >= 1000 ){  
            ServicosDiv.scrollLeft += document.querySelectorAll('#ServicosDiv img')[0].offsetWidth * 1.32
       }else{
        ServicosDiv.scrollLeft += document.querySelectorAll('#ServicosDiv img')[0].offsetWidth
       }
    }


    return(
        <> 
        <br/>
            <Topicos partUm='NOSSOS' partDois='SERVIÇOS' id='topicoServicos' width='50%'/>
            
            <Listaservicos  id='lista-servicos' > 
                
                <img 
                    src={setaEsquerda} 
                    alt='seta para Esquerda' 
                    className='setas' 
                    id='SetaEsquerda' 
                    onClick={handleSetaEsquerda}
                    onLoad={(e)=>{
                            if(document.body.offsetWidth >1000){
                                e.target.style.marginTop = (document.querySelectorAll('.setas')[1].offsetHeight - document.querySelector('#ServicosDiv').offsetHeight) * 2+'px'
                            }else{
                                e.target.style.marginTop = '80px'
                            }
                            if(document.getElementById('ServicosDiv').scrollLeft === 0){
                                e.target.style.display ='none'
                            }
                    }}
                    />
                
                <div id='ServicosDiv' onScroll={(e) =>{
                    
                    document.querySelectorAll('.setas')[1].style.display ='block'
                    document.querySelectorAll('.setas')[0].style.display ='block'

                        if(e.target.scrollLeft === 0){
                            document.querySelectorAll('.setas')[0].style.display ='none'
                        }else if(e.target.scrollLeft >= (e.target.scrollWidth - e.target.offsetWidth)-50){
                            document.querySelectorAll('.setas')[1].style.display ='none'
                        }

                }}>
                {
                    Itens.map((ItemAtual, i) =>{
                        return(

                            <ItemLista  id={'ItemLista'+ItemAtual.key} key={ItemAtual.key} onClick={(e) =>{

                                    let servicosDiv = document.querySelector('#ServicosDiv').children
                                    for(let x = 0; x< servicosDiv.length;x++){
                                        servicosDiv[x].children[2].style.display = 'none'
                                        servicosDiv[x].children[2].style.height = '0px'
                                    }
                                    if(visivel === false){
                                        setVisivel(true)
                                        e.target.parentElement.lastChild.style.display = 'block'
                                        e.target.parentElement.lastChild.style.height = 'fit-content'
                                    }else{
                                        setVisivel(false)
                                        e.target.parentElement.lastChild.style.display = 'none'
                                        e.target.parentElement.lastChild.style.height = '0px'
                                    }
                            }}>
                                <h2>
                                    {ItemAtual.nome}
                                </h2>
                                <img src={ItemAtual.img} alt={ItemAtual.nome} loading="lazy"/>
                                <Animated
                                 animationIn="slideInUp" 
                                 animationOut="slideOutDown" 
                                 animationInDuration={700} 
                                 animationOutDuration={700}
                                 isVisible={visivel} 
                                 style={{backgroundColor: '#FFF', width:'100%', height:'0px'}}
                                >
                                    {parse(ItemAtual.texto)}
                                </Animated>
                            </ItemLista >

                            )
                            })
                            }
                </div>
                    <img 
                        src={setaDireita} 
                        alt='seta para Esquerda'  
                        className='setas' 
                        id='SetaDireita' 
                        onClick={handleSetaDireita} 
                        onLoad={(e)=>{
                            if(document.body.offsetWidth >1000){
                                e.target.style.marginTop = (document.querySelectorAll('.setas')[1].offsetHeight - document.querySelector('#ServicosDiv').offsetHeight) * 2+'px'
                            }else{
                                e.target.style.marginTop = '80px'
                            }

                    }}/>
            </Listaservicos>
        </>
    )
}

export default ListaServicos