import React from 'react'
import AboutShe from './about'
import BannerPage from './banner'
import Comment from './comment'
import Contato from './contact'
import Portifolios from './portifolio'
import ListaServicos from './services'
import SiteRodape from './footer'

function SiglePage(){

    return(
        <>
            
            <BannerPage />
            
            <AboutShe />

            <ListaServicos />

            <Comment/>

            <Portifolios/>

            <Contato/>

            <SiteRodape/>
        </>
    )
}

export default SiglePage