import styled from 'styled-components'
//import oswald from '../font/Oswald.ttf'
//import camila from '../font/Camaly.ttf'



export const Topico = styled.div`
width:auto;
height:25px;
padding:0px;
padding-top:20px;
padding-bottom:20px;
margin:0px;
position:relative;
float:right;
right:0px;
text-align:right;
display:flex;
align-items: center;
justify-content: flex-end;
z-index:1;

*{
    margin:0px;
    padding:0px;
    z-index:1;
}

H1, div h1{
    font-family:Oswald;
    font-weight:400;
    font-size:16px;
    color:#000;
    display:inline;
    letter-spacing:4px;
}
div{
    background: #B98DBE;
    color:#FFF;
    text-align:left;
    display:inline-block;
    width:120px;
    position:relative;
    float:right;
    right:0px;
    padding-left:5px;
    height:24px;
}
div h1{
    color:#FFF;
    line-height:25px;
}

@media screen and (min-width: 1000px){
    height:80px;
    width:100%;

    H1, div h1{
        font-size:36px;
    }
    div{
        width:70%;
        height:50px;
    }
    div h1{
        line-height:50px;
    }
}

`

function Topicos(props){
    return(
        <Topico id={props.id} style={{width:props.width}}>
            <h1>
                {props.partUm}
            </h1>
            <div>
                <h1>
                    {props.partDois}
                </h1>
            </div>
        </Topico>
    )
}

export default Topicos