import React from "react"
import styled from "styled-components"
import youtube from '../Images/youtube.png'
import instagram from '../Images/instagram.png'
import facebook from '../Images/facebook.png'
import twitter from '../Images/twitter.png'


const Rodape = styled.footer`

    width:100%;
    height:fit-content;
    position:relative;
    background-color:#B98DBE;
    display:grid;
    grid-column-template:50% 50%;
    grid-row-template:fit-content fit-content;
    color:#FFF;
    font-family:Camaly;
    font-size:18px;
    font-weight:600;

    *{
        padding:0;
        margin:0;
    }

    .menuRodape{
        list-style-type:none;
        width:fit-content;
        color:#FFF;
        font-family:Camaly;
        font-size:16px;
        font-weight:600;
        grid-column-start:1;
        grid-column-end:2;
        grid-row-start:1;
        grid-row-end:2;
        margin-left:30px;
        margin-top:10px;
        margin-bottom:20px;
    }
    .menuRodape li{
        height:30px;
    }
    
    .redesRodape{
        display: grid;
        grid-gap:10px;
        width:fit-content;
        height:fit-content;
        grid-template-columns:50% 50%;
        grid-template-rows:50% 50%;
        padding:0px;
        list-style-type:none;
        grid-column-start:2;
        grid-column-end:3;
        grid-row-start:1;
        grid-row-end:2;
        margin-top:30px;
    }
    .redesRodape li{
        width:100%;
        height:fit-content;
    }
    .redesRodape li img{
        width:45px;
        height:auto;
    }

    p{
        list-style-type:none;
        grid-column-start:1;
        grid-column-end:3;
        grid-row-start:2;
        grid-row-end:3;
        text-align:center;
    }
    @media screen and (min-width: 1000px){

        .menuRodape{
            width:100%;
            font-size:20px;
        }
        .menuRodape li{
            height:30px;
            width:fit-content;
            margin-left:auto;
            margin-right:auto;
        }
        
        .redesRodape{
            display: grid;
            grid-gap:10px;
            width:fit-content%;
            height:fit-content;
            grid-template-columns:50% 50%;
            grid-template-rows:50% 50%;
            padding:0px;
            list-style-type:none;
            grid-column-start:2;
            grid-column-end:3;
            grid-row-start:1;
            grid-row-end:2;
            margin-top:30px;
            margin-left:auto;
            margin-right:auto;
        }
        .redesRodape li{
            width:fit-content;
            height:fit-content;
        }
        .redesRodape li img{
            width:55px;
            height:auto;
        }

        p{
            list-style-type:none;
            grid-column-start:1;
            grid-column-end:3;
            grid-row-start:2;
            grid-row-end:3;
            text-align:center;
            font-size:24px;
        }
    }
`

function SiteRodape(){

    const handleMenu = e => {

        if(e.target.innerText === 'HOME'){
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
        }
        
        if(e.target.innerText === 'SOBRE'){
            document.body.scrollTop = document.getElementById('topicoSobre').offsetTop - 50 // For Safari
            document.documentElement.scrollTop = document.getElementById('topicoSobre').offsetTop - 50
        }
        
        if(e.target.innerText === 'NOSSOS SERVIÇOS'){
            document.body.scrollTop = document.getElementById('topicoServicos').offsetTop - 50 // For Safari
            document.documentElement.scrollTop = document.getElementById('topicoServicos').offsetTop - 50
        }
        
        if(e.target.innerText === 'COMENTÁRIOS'){
            document.body.scrollTop = document.getElementById('topicoComentario').offsetTop - 50 // For Safari
            document.documentElement.scrollTop = document.getElementById('topicoComentario').offsetTop - 50
        }
        
        if(e.target.innerText === 'PORTIFÓLIO'){
            document.body.scrollTop = document.getElementById('topicoPortifolio').offsetTop - 50 // For Safari
            document.documentElement.scrollTop = document.getElementById('topicoPortifolio').offsetTop - 50
        }
        
        if(e.target.innerText === 'CONTATO'){
            document.body.scrollTop = document.getElementById('topicoContato').offsetTop - 50 // For Safari
            document.documentElement.scrollTop = document.getElementById('topicoContato').offsetTop - 50
        }

    }


    return(

        <>
            <Rodape>

                <ul className='menuRodape' onClick={handleMenu}>
                    <li>HOME</li>
                    <li>SOBRE</li>
                    <li>NOSSOS SERVIÇOS</li>
                    <li>COMENTÁRIOS</li>
                    <li>PORTIFÓLIO</li>
                    <li>CONTATO</li>
                </ul>

                <ul className='redesRodape'>
                    <li><img src={twitter} alt='Icone Twitter' /></li>
                    <a href='https://www.facebook.com/atelienatalialucena'  target='_blank' rel="noreferrer">
                                <li>
                                    <img src={facebook} alt='Icone facebook' />
                                </li>
                            </a>
                            <a href='https://www.instagram.com/natalialuccena/' target='_blank' rel="noreferrer">
                                <li>
                                    <img src={instagram} alt='Icone instagram' />
                                </li>
                            </a>
                    <li><img src={youtube} alt='Icone youtube' /></li>
                </ul>


                <p>Todos os direitos reservados BI4U Solutions</p>

            </Rodape>
        </>

    )
}

export default SiteRodape